import React, { type FC } from 'react';

export const Favicon: FC = () => {
    return (
        <>
            <link rel="icon" href="/assets/favicon/32x32.png" sizes="32x32" type="image/png" />
            <link rel="icon" href="/assets/favicon/48x48.png" sizes="48x48" type="image/png" />
            <link rel="icon" href="/assets/favicon/72x72.png" sizes="72x72" type="image/png" />

            <link rel="apple-touch-icon" href="/assets/favicon/48x48.png" sizes="48x48" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/72x72.png" sizes="72x72" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/96x96.png" sizes="96x96" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/144x144.png" sizes="144x144" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/192x192.png" sizes="192x192" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/256x256.png" sizes="256x256" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/384x384.png" sizes="384x384" type="image/png" />
            <link rel="apple-touch-icon" href="/assets/favicon/512x512.png" sizes="512x512" type="image/png" />
        </>
    );
};
