import React, { type FC } from 'react';

import { type BreadcrumbsProps } from '@/components/navigations/breadcrumbs';
import { site } from '@/utils/site';

const basicItem = {
    '@type': 'ListItem',
    position: 1,
    item: {
        '@type': 'Thing',
        id: site.url,
        name: site.name,
    },
};

export const BreadcrumbsSchema: FC<BreadcrumbsProps> = ({ render }) => {
    const schema = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            basicItem,
            ...render
                .filter((element) => element.uri?.url)
                .map((element, i) => ({
                    '@type': 'ListItem',
                    position: i + 2,
                    item: {
                        '@type': 'Thing',
                        id: site.url + element.uri!.url,
                        name: element.name,
                    },
                })),
        ],
    });

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />;
};
