import { graphql, type HeadFC, type PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { type FC } from 'react';

import { ContentWithHeader } from '@/components/content/content-with-header';
import { Html } from '@/components/content/html';
import { AppHead } from '@/components/head';

interface NotFoundPage {
    page: GraphqlSelect.Page<'id' | 'metaTitle' | 'metaDescription' | 'name' | 'body'>;
}

const NotFoundPage: FC<PageProps<NotFoundPage>> = ({ data: { page } }) => {
    return (
        <ContentWithHeader
            as="div"
            breadcrumbs={[{ name: page.name }]}
            image={<StaticImage width={502} height={282} src="../../static/assets/404/not-found.png" alt="" />}
        >
            <h1 className="fs-56">{page.name}</h1>

            <Html style={{ margin: '15px 0 24px' }} className="fs-24">
                {page.body.html}
            </Html>
        </ContentWithHeader>
    );
};

export const Head: HeadFC<NotFoundPage> = ({ data: { page } }) => {
    return (
        <AppHead title={page.metaTitle} description={page.metaDescription} ogImage={{ id: page.id, type: 'pages' }} />
    );
};

export const query = graphql`
    query NotFoundPage {
        page: pages(uri: { url: { eq: "/404/" } }) {
            id
            metaTitle
            metaDescription
            name

            body {
                html
            }
        }
    }
`;

export default NotFoundPage;
