import { Link } from 'gatsby';
import React, { type CSSProperties, type FC, type HTMLAttributes } from 'react';

import { BreadcrumbsSchema } from '@/components/head/seo/schemas/breadcrumbs-schema';
import { cn } from '@/utils/class-name';
import { site } from '@/utils/site';

export interface BreadcrumbsProps {
    render: Graphql.Breadcrumbs;
}

const listStyle: CSSProperties = {
    gap: '10px',
    overflow: 'auto hidden',
    paddingBottom: '5px',
    marginBottom: '-5px',
};

const listItemStyle: CSSProperties = {
    flex: '0 0 auto',
    gap: '5px',
};

export const Breadcrumbs: FC<BreadcrumbsProps & HTMLAttributes<unknown>> = ({ render, style, className, ...props }) => {
    return (
        <nav {...props} style={{ ...style, flex: '0 0 100%' }} className={cn('breadcrumbs', className)}>
            <ol style={listStyle} className="d-flex ai-center unstyled-list">
                <li style={listItemStyle} className="d-flex ai-center with-chevron-suffix">
                    <Link to="/">{site.name}</Link>
                </li>

                {render.map((element, id) => (
                    <li
                        key={id}
                        style={listItemStyle}
                        className={cn('d-flex ai-center', id + 1 !== render.length && 'with-chevron-suffix')}
                    >
                        {element.uri ? <Link to={element.uri.url}>{element.name}</Link> : <b>{element.name}</b>}
                    </li>
                ))}
            </ol>

            <BreadcrumbsSchema render={render} />
        </nav>
    );
};
